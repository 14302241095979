"use client";

import React, { useEffect, useState, useRef } from "react";
import NavLink from "./components/nav-link";
import CtaButton from "@/modules/common/components/cta-button";
import GeneseqLogo from "@/modules/common/icons/geneseq-logo";

import styles from "./navstyle.module.scss";
import Link from "next/link";
import useCustomer from "@/lib/hooks/useCustomer";
import GetStartedModal from "./components/get-started-modal";

const Nav = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const { customer, signedIn } = useCustomer();

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 80) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        headerRef.current &&
        !headerRef.current.contains(event.target as Node)
      ) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNavLinkClick = () => {
    setIsActive(false);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <header
        className="w-full z-[50] bg-transparent relative sm:fixed mt-0 sm:mt-7"
        ref={headerRef}
      >
        <div
          className={`${styles.header_container}  ${
            isVisible ? "fade-out" : "fade-in"
          }
        container mx-auto bg-white text-customGray flex items-center justify-between py-4 px-6`}
        >
          <div className="w-auto flex items-start">
            <Link href={"/"} style={{ display: "flex", alignItems: "center" }}>
              <GeneseqLogo />
              {/* <span className={`${styles.logo_text} ml-2 font-sans tracking-lg`}>
              Geneseq
              </span> */}
            </Link>
          </div>

          <button
            id="toggleMenu"
            className={`${styles.hamburger_btn} md:hidden focus:outline-none ${
              isActive && styles.activated
            }`}
            onClick={() => setIsActive(!isActive)}
          >
            <span className={`${styles.hamburger_box} `}>
              <span className={`${styles.top} `}></span>
              <span className={`${styles.bottom} `}></span>
            </span>
          </button>

          <nav
            className={`${styles.nav_container} flex items-center text-customGray space-x-4`}
          >
            <ul
              className="flex items-center  gap-7 font-normal"
              onClick={handleNavLinkClick}
            >
              {/* <NavLink href={"/"}>Home</NavLink> */}
              <NavLink href={"/about"}>About</NavLink>
              <NavLink href={customer ? `/licensee/shop` : "/shop"}>
                Shop
              </NavLink>
              <NavLink href={"/resources"}>Resources</NavLink>
              {signedIn ? (
                <li className={`${styles.item} font-sans`}>
                  {/* py-3 not working */}
                  <CtaButton href="/licensee/profile" py={2} h={10} px={4}>
                    Account
                  </CtaButton>
                </li>
              ) : (
                <li className={`${styles.item} font-sans`}>
                  {/* py-3 not working */}
                  <CtaButton
                    onClick={() => setModalOpen(true)}
                    py={2}
                    h={10}
                    px={4}
                  >
                    Get Started
                  </CtaButton>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </header>
      <GetStartedModal isModalOpen={isModalOpen} closeModal={closeModal} />
    </>
  );
};

export default Nav;
