"use client";

import React from "react";
import constants from "@/lib/constants";

import LocalizedClientLink from "@/modules/common/components/localized-client-link";
import {
  IgButton,
  LinkedInButton,
  YoutubeButton,
} from "@/modules/common/components/socmed-button/SocmedButtons";

import styles from "./footerstyle.module.scss";
import Link from "next/link";
import WrapperContainer from "@/modules/common/components/max-width-wrapper";

const { appConfig } = constants;

interface GroupedLinkLabelProps {
  text: string;
}

interface LinkItem {
  label: string;
  path: string;
}

interface GroupedLinksProps {
  links: LinkItem[];
}

const GroupedLinkLabel = ({ text }: GroupedLinkLabelProps) => {
  return <p className={`${styles.group_label} font-sans`}>{text}</p>;
};

const GroupedLinks = ({ links }: GroupedLinksProps) => {
  return (
    <ul>
      {links.map(({ label, path }, index) => {
        return (
          <li key={index} className={`${styles.item} font-sans pb-3`}>
            <LocalizedClientLink href={path}>{label}</LocalizedClientLink>
          </li>
        );
      })}
    </ul>
  );
};

export default function Footer() {
  return (
    <footer className={`${styles.container}`}>
      <WrapperContainer>
        <div className="flex flex-wrap border-b border-solid border-gray-700 pb-10">
          <div className={`${styles.item_logo} md:w-3/5 flex items-start`}>
            {/* <GeneseqLogo fill={"#E8E8E8"} />
          <span className={`${styles.logo_text} ml-2`}>Geneseq</span> */}

            <Link href={"/"}>
              <img
                src={"/logo/geneseq-logo-white.webp"}
                alt="Geneseq Logo"
                className="h-10"
              />
            </Link>

            <Link href={"#"}>
              <img
                src={"/images/back-to-top.svg"}
                alt="Geneseq Logo"
                className="h-12 md:hidden"
              />
            </Link>
          </div>
          <div className={`${styles.item_menu} md:w-2/5 items-end`}>
            <div className={`${styles.menu_wrap} flex justify-end gap-7`}>
              {/* <div className="w-1/3 flex flex-col items-start">
                <GroupedLinkLabel text={"Shop"} />
                <GroupedLinks links={appConfig.footerLinks.shopLinks} />
              </div> */}

              <div className="w-1/3 flex flex-col items-start">
                <GroupedLinkLabel text={"Company"} />
                <GroupedLinks links={appConfig.footerLinks.companyLinks} />
              </div>

              <div className="w-1/3 flex flex-col items-start">
                <GroupedLinkLabel text={"Links"} />
                <GroupedLinks links={appConfig.footerLinks.generalLinks} />
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.copyright} flex flex-wrap mt-6`}>
          <div className="w-full md:w-3/4 flex items-center">
            <p>
              © 2024 Health Span FZCO. A{" "}
              <a
                className="hover:underline hover:text-[#fff]"
                target="_blank"
                rel="noopener noreferrer"
                href="https://healthspan.digital/"
              >
                Healthspan Digital
              </a>{" "}
              Holdings Limited Company.
            </p>
          </div>
          <div
            className={`${styles.some_bt} w-full md:w-1/4 flex justify-end gap-4`}
          >
            <IgButton />
            <LinkedInButton />
            <YoutubeButton />
          </div>
        </div>
      </WrapperContainer>
    </footer>
  );
}
